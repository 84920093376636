"use client";

import {
  CTA_NAV_BAR_QUERY_PARAM,
  CTA_NAV_BAR_TO_BOOK_DEMO,
  CTA_NAV_BAR_UPLOAD_RESUME,
  USER_CLICKS_NAV_BAR_CTA_BOOK_DEMO,
  USER_CLICKS_NAV_BAR_CTA_UPLOAD_RESUME,
} from "@/app/constants/splitTestingConstants";
import TryDemoNow from "@/app/welcome/DemoNow/TryDemoNow";
import { getUrlToBookDemoPage } from "@/utils/uiHelpers/navigationHelper";
import { useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";

const OptionalCtaAction = () => {
  const searchParams = useSearchParams();
  const [ctaQueryParam, setCtaQueryParam] = useState<string | null>(null); // Start with null

  useEffect(() => {
    const param = searchParams.get(CTA_NAV_BAR_QUERY_PARAM);
    setCtaQueryParam(param ? param.toLowerCase() : "");
  }, [searchParams]);

  if (ctaQueryParam === null) return null; // Ensure SSR and CSR match by rendering nothing initially

  const handleBookDemoClick = () => {
    window.location.href = getUrlToBookDemoPage();
  };

  if (ctaQueryParam === CTA_NAV_BAR_TO_BOOK_DEMO.toLowerCase()) {
    return (
      <a
        onClick={handleBookDemoClick}
        className="btn btn-success btn-sm rounded-3xl whitespace-nowrap normal-case mx-10"
        data-track={USER_CLICKS_NAV_BAR_CTA_BOOK_DEMO}
      >
        Book My Demo
      </a>
    );
  }

  if (ctaQueryParam === CTA_NAV_BAR_UPLOAD_RESUME.toLowerCase()) {
    return (
      <TryDemoNow
        buttonText="Upload My Candidate's Resume"
        buttonClassName="btn btn-success btn-sm rounded-3xl whitespace-nowrap normal-case mx-10"
        buttonDataTrack={USER_CLICKS_NAV_BAR_CTA_UPLOAD_RESUME}
      />
    );
  }

  return null;
};

export default OptionalCtaAction;
