/**
 * This file is used to define the constants for split testing and all things advertisements.
 *
 * It'll have the names of events used to track the split testing, engagement, and advertisement events.
 *
 **/

/**
 * Data Track Events for Split Testing and Engagemnet Tracking.
 *
 * denote by data-track attribute in the HTML elements on clickable items
 */
export const USER_CLICKS_TRY_DEMO_NOW = "user_clicks_try_demo_now";
export const USER_UPLOADS_RESUME_TO_DEMO = "try_demo_uploaded_resume";
// export const USER_WATCHES_VIDEO_HOMEPAGE = "user_watches_video_homepage";
export const USER_BOOK_DEMO_FORM_SUBMISSION = "user_book_demo_form_submission";


// Nav Bar 
export const USER_CLICKS_NAV_BAR_LOGO = "user_clicks_nav_bar_logo"
export const USER_CLICKS_NAV_BAR_HOW_IT_WORKS = "user_clicks_nav_bar_how_it_works";
export const USER_CLICKS_NAV_BAR_PRICING = "user_clicks_nav_bar_pricing";
export const USER_CLICKS_NAV_BAR_CONTACT_EMAIL = "user_clicks_nav_bar_contact_email";
export const USER_CLICKS_NAV_BAR_CONTACT_BOOK_DEMO = "user_clicks_nav_bar_contact_book_demo";
export const USER_CLICKS_NAV_BAR_CONTACT_REQUEST_FEATURE = "user_clicks_nav_bar_contact_request_feature";
export const USER_CLICKS_NAV_BAR_SIGN_UP = "user_clicks_nav_bar_sign_up";
export const USER_CLICKS_NAV_BAR_SIGN_IN = "user_clicks_nav_bar_sign_in";

// Sales-landing pages
export const USER_CLICKS_NAV_BAR_CTA_BOOK_DEMO =
  "user_clicks_nav_bar_cta_book_demo";
export const USER_CLICKS_NAV_BAR_CTA_UPLOAD_RESUME =
  "user_clicks_nav_bar_cta_upload_resume";
export const USER_CLICKS_HERO_CTA_UPLOAD_RESUME =
  "user_clicks_hero_cta_upload_resume";
export const USER_CLICKS_HERO_CTA_DEMO = "user_clicks_hero_cta_demo";
export const USER_CLICKS_HERO_CTA_GET_STARTED =
  "user_clicks_hero_cta_get_started";
export const USER_CLICKS_CTA_SECTION_BOOK_DEMO =
  "user_clicks_cta_section_book_demo";
export const USER_CLICKS_CTA_SECTION_UPLOAD_RESUME =
  "user_clicks_cta_section_upload_resume";
export const USER_CLICKS_FOOTER_CTA_START_FREE_TRIAL =
  "user_clicks_footer_cta_start_free_trial";
export const USER_UPLOADS_RESUME_TO_TRY_NOW_DEMO =
  "user_uploads_resume_to_try_now_demo";
export const USER_USES_SAMPLE_RESUME_TO_TRY_NOW_DEMO =
  "user_uses_sample_resume_to_try_now_demo";
/**
 * CTA NAVIGATION For Navigation Bar. Text is seen in the URL.
 */
export const CTA_NAV_BAR_QUERY_PARAM = "ctaNav";
export const CTA_NAV_BAR_TO_BOOK_DEMO = "bookDemo";
export const CTA_NAV_BAR_UPLOAD_RESUME = "uploadResume";
