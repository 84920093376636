import { ROUTE_SIGNUP_PAGE } from "@/app/constants/constants";
/**
 *
 * If function depends on cookies, authentication,
 *  or runtime values, it forces the page to be dynamic.
 *
 */

export const getUrlToBookDemoPage = () => {
  const url = "/sales-landing/book-demo" + "#ctaSection";
  return url;
};

export const getUrlToSignUpPage = () => {
  return ROUTE_SIGNUP_PAGE;
};
